<template>
    <div class="col-12 d-flex product-details-border position-relative pe-0">
        <div class="row w-100 pe-lg-0 me-1 py-2">
            <div class="col-md-3 col-12">
                <div class="mb-1">
                    <label class="form-label" for="product_id">Item</label>
                    <v-select :options="specificationBrandList" :filterable="false" label="name"
                        placeholder="Select Item" v-model="data.brand" ref="bandField"
                        @option:selected="optionSelected">
                        <template v-slot:option="option">
                            {{ option.name }}
                        </template>
                        <template #list-footer>
                            <li v-show="hasNextPage" ref="load" class="loader">
                                Loading more options...
                            </li>
                        </template>
                    </v-select>
                    <span class="text-danger" v-if="brandError">{{ brandError }}</span>
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="color_id">Color</label>
                    <v-select :options="specificationColorList" :filterable="false" label="name"
                        placeholder="Select Item" v-model="data.color" ref="colorField"
                        @option:selected="optionSelected">
                        <template v-slot:option="option">
                            {{ option.name }}
                        </template>
                        <template #list-footer>
                            <li v-show="hasNextPage" ref="load" class="loader">
                                Loading more options...
                            </li>
                        </template>
                    </v-select>
                    <span class="text-danger" v-if="colorError">{{ colorError }}</span>
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="size_id">Size</label>
                    <v-select :options="specificationSizeList" :filterable="false" label="name"
                        placeholder="Select Item" v-model="data.size" ref="sizeField" @option:selected="optionSelected">
                        <template v-slot:option="option">
                            {{ option.name }}
                        </template>
                        <template #list-footer>
                            <li v-show="hasNextPage" ref="load" class="loader">
                                Loading more options...
                            </li>
                        </template>
                    </v-select>
                    <span class="text-danger" v-if="sizeError">{{ sizeError }}</span>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="mb-1">
                    <label class="form-label" for="product_id">Product</label>
                    <v-select :options="specificationProductList" :filterable="false" label="name"
                        placeholder="Select Product" v-model="data.product" ref="productField">
                        <template v-slot:option="option">
                            {{ option.name }}
                        </template>
                        <template v-slot:no-options>
                            <li class="no-options">
                                No products found
                            </li>
                        </template>
                        <template #list-footer>
                            <li v-show="hasNextPage" ref="load" class="loader">
                                Loading more options...
                            </li>
                        </template>
                    </v-select>
                    <span class="text-danger" v-if="productError">{{ productError }}</span>
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="rate">Rate</label>
                    <vField v-model="data.rate" name="rate" type="number" class="form-control text-right px-1" />
                    <span class="text-danger" v-if="rateError && rateError !== ''">{{ rateError }}</span>
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="quantity">Quantity</label>
                    <input v-model="data.quantity" name="quantity" type="number" class="form-control text-right"
                        ref="qtyField" @blur="validateQty" />
                    <span class="text-danger" v-if="quantityError && quantityError !== ''">{{ quantityError }}</span>
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="quantity">Amount</label>
                    <vField readonly v-model="totalAmount" name="amount" type="number"
                        class="form-control text-right" />
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label">Exp. Delivery Date</label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="date" placeholder="" v-model="data.delivery_date">
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="style">Style</label>
                    <input v-model="data.style" type="text" class="form-control" placeholder="Input style" id="style">
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="po_no">PO NO:</label>
                    <input v-model="data.po_number" type="text" class="form-control" placeholder="Input Po No"
                        id="po_number">
                </div>
            </div>

            <div class="col-md-12 col-12">
                <div class="mb-1">
                    <label class="form-label" for="note">Note:</label>
                    <input v-model="data.description" type="text" class="form-control" placeholder="Input Note"
                        id="note">
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <button class="btn btn-primary btn-block waves-effect waves-float waves-light"
                        @click="handleAddProduct">Add</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, onMounted } from "@vue/runtime-core";
import { watch } from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleInventory from '@/services/modules/inventory'
import handlePurchaseAndSales from "@/services/modules/purchase";
import { useRoute } from "vue-router";
import { floor, isEmpty, trim } from "lodash";

let loading = ref(false)
const singleData = {
    product: null,
    product_name: '',
    product_id: null,
    brand: null,
    brand_name: '',
    brand_id: null,
    color: null,
    color_name: '',
    color_id: null,
    size: null,
    size_name: '',
    size_id: null,
    item_full_name: '',
    unit: null,
    rate: null,
    quantity: null,
    amount: 0.00,
    discount_amount: 0,
    discount_percent: 0,
    vat: 0,
    delivery_date: null,
    style: '',
    po_number: '',
    description: '',
}

const route = useRoute();
const { fetchProductsForSpecifications, fetchProductStock } = handlePurchaseAndSales()
const $emit = defineEmits(['handleProductAdd'])
const batchError = ref('')
const expDateError = ref('')
const rateError = ref('')
const quantityError = ref('')
const brandError = ref('')
const colorError = ref('')
const sizeError = ref('')
const productError = ref('')
const qtyField = ref(null)
const productField = ref(null)
const colorField = ref(null)
const sizeField = ref(null)
const updateIndex = ref(null)
const data = ref({ ...singleData })
const { fetchSpecification, ...rest } = handleInventory()
const companyQuery = `?company_id=${route.params.companyId}`;

const companyId = computed(() => {
    return route.params.companyId
})
let specificationBrandList = ref([])
let specificationColorList = ref([])
let specificationSizeList = ref([])
let specificationProductList = ref([]);

const fullNameCreate = computed(() => {
    return [data.value.brand?.name, data.value.color?.name, data.value.size?.name].filter(Boolean).join(' - ');
})
const totalAmount = computed(() => {
    return (data.value.rate * data.value.quantity).toFixed(4);
})

const selectedProduct = computed(() => data.value.product)

const resetProductList = () => {
    specificationProductList.value = [];
    data.value.product = null;
};

const productsForSpecifications = async () => {
    if (!data.value.brand && !data.value.color && !data.value.size) {
        resetProductList()
        return;
    }

    try {
        const query = `?company_id=${route.params.companyId}&brand_id=${data.value.brand.id}&color_id=${data.value.color.id}&size_id=${data.value.size.id}`;
        const response = await fetchProductsForSpecifications(query);
        if (!response) {
            resetProductList()
            return;
        }
        specificationProductList.value = response.data;
        if (specificationProductList.value.length > 1) {
            data.value.product = null;
        }
    } catch (error) {
        resetProductList()
    }
}

watch([() => data.value.brand, () => data.value.color, () => data.value.size], () => {
    productsForSpecifications();
});

watch(fullNameCreate, (fullName) => {
    data.value.item_full_name = fullName;
})

watch(totalAmount, (newValue) => {
    data.value.amount = newValue
})

watch(selectedProduct, (newValue) => {
    if (newValue && newValue.id) {
        fetchProductStock(newValue.id, `${companyQuery}&skip_stock_check=1`).then((res) => {
            if (res.data) {
                data.value.product_id = newValue.id;
                data.value.selling_rate = res.data.sales_rate ? res.data.sales_rate : 0;
                data.value.product_name = newValue.text;
                return;
            }
            data.value.product_id = null;
            data.value.selling_rate = 0;
            data.value.product_name = "";
        })
            .catch(() => { })
    }
})

const focusProductSelect = () => {
    productField.value.dropdownRef.searchEl.focus()
}
const focusColorSelect = () => {
    colorField.value.dropdownRef.searchEl.focus()
}
const focusSizeSelect = () => {
    sizeField.value.dropdownRef.searchEl.focus()
}

const validateData = () => {
    batchError.value = ""
    expDateError.value = ""
    rateError.value = ""
    quantityError.value = ""
    brandError.value = ""
    colorError.value = ""
    sizeError.value = ""
    productError.value = ""

    let errorsFound = false;

    if (!data.value.brand) {
        brandError.value = "Brand is required"
        errorsFound = true;
    }

    if (!data.value.color) {
        colorError.value = "Color is required"
        errorsFound = true;
    }

    if (!data.value.size) {
        sizeError.value = "Size is required"
        errorsFound = true;
    }

    if (!data.value.product) {
        productError.value = "Product is required"
        errorsFound = true;
    }

    if (!data.value.rate || data.value.rate <= 0) {
        rateError.value = "Rate must be more than 0"
        errorsFound = true;
    }

    if (!validateQty()) {
        errorsFound = true;
    }

    if (!data.value.quantity || data.value.quantity <= 0) {
        quantityError.value = "Quantity must be more than 0"
        errorsFound = true;
    }

    return errorsFound;
}

const validateQty = () => {
    let quantity = Number(data.value.quantity)

    if (!Number.isInteger(quantity)) {
        quantityError.value = "The quantity must be an Integer";
        data.value.quantity = floor(data.value.quantity)
        qtyField.value.focus();
        return false;
    }

    return true;
}

const handleAddProduct = () => {
    if (validateData()) {
        return;
    }
    $emit('handleProductAdd', { ...data.value }, updateIndex.value);
    data.value = { ...singleData };
    focusProductSelect();
    focusColorSelect();
    focusSizeSelect();

    if (updateIndex.value !== null && updateIndex.value !== undefined) {
        updateIndex.value = null;
    }
}

const setData = (object) => {
    if (object) {
        data.value = { ...object };
    }
}

onMounted(() => {
    loading.value = true
    rest.fetchSpecificationValue('brand', companyId.value).then(res => {
        specificationBrandList.value = res.data
        loading.value = false
    }).catch((err) => {
        handleError(err)
    })

    rest.fetchSpecificationValue('color', companyId.value).then(res => {
        specificationColorList.value = res.data
        loading.value = false
    }).catch((err) => {
        handleError(err)
    })

    rest.fetchSpecificationValue('size', companyId.value).then(res => {
        specificationSizeList.value = res.data
        loading.value = false
    }).catch((err) => {
        handleError(err)
    })
})

const optionSelected = (option) => {
};

defineExpose({ updateIndex, setData })
</script>
