export const useAsyncDropdownHelper = () => {

    /**
     * Generating label with patient id
     *
     * @param {string} label
     * @param {Object} patient
     */
    const getNameWithSerial = (label, patient) => {
        if (
            "company_roles" in patient &&
            patient.company_roles.length > 0 &&
            "pivot" in patient.company_roles[0]
        ) {
            return `${label} (${patient.company_roles[0].pivot.serial_no})`
        }

        return label
    }

    /**
     * Generating label with mobile number
     *
     * @param {string} label
     * @param {Object} patient
     */
    const getNameWithMobile = (label, patient) => {
        if (
            "profile_items" in patient &&
            patient.profile_items.length > 0
        ) {
            const mobileData = patient.profile_items.find(item => item.field === "mobile_no")
            return mobileData && "value" in mobileData ? `${label} - ${mobileData.value}` : label
        }

        return label
    }

    /**
     * Generating label with patient serial and mobile number
     *
     * @param {Object} patient
     */
    const formatPatientLabel = (patient) => {
        let label = patient.name;

        label = getNameWithSerial(label, patient)

        label = getNameWithMobile(label, patient)

        return label;
    }

    return {
        getNameWithSerial,
        getNameWithMobile,
        formatPatientLabel,
    }

}