<template>
    <div class="table-responsive mb-4">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Item Name</th>
                    <th>Color</th>
                    <th>Size</th>
                    <!-- <th>Unit</th> -->
                    <th>Rate</th>
                    <th>Qty</th>
                    <th>Amount</th>
                    <th>Exp. Delivery Date</th>
                    <th>Style</th>
                    <th>PO No</th>
                    <th>Note</th>
                    <th>Action</th>
                </tr>   
            </thead>
            <tbody v-if="itemDetails">
                <tr v-for="(item, index) in itemDetails">
                    <td>{{ item.brand.name }}</td>
                    <td>{{ item.color.name }}</td>
                    <td>{{ item.size.name }}</td>
                    <td class="d-none">{{ item.unit }}</td>
                    <td class="d-none">{{ item.discount_amount }}</td>
                    <td>{{ item.rate }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.delivery_date }}</td>
                    <td>{{ item.style }}</td>
                    <td>{{ item.po_number }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        <button
                            class="btn btn-sm btn-primary px-1 me-1"
                            @click="() => handleEditItem(item, index)"
                        >
                            <i class="fa fa-pencil-alt"></i>
                        </button>
                        <button
                            class="btn btn-sm btn-danger px-1"
                            @click="() => $emit('removeBill', index)"
                        >
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    const props = defineProps({
        itemDetails: {
            type: Array,
            default: []
        },
        formRef: {
            type: Object,
        }
    })

    const handleEditItem = (item, index) => {
        props.formRef.setData(item)
        props.formRef.updateIndex = index;
    }

    
</script>