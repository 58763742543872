<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton btnTitle="List" :showBtn="true" :showAddNew="false" title="Create Proforma Invoice (PI)"
                @onClickCloseButton="navigateToListPage" />
            <div class="row gy-1">
                <!-- Customer Name Field -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Customer Name: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect placeholder="Select Customer Name" v-model="contactProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalContactOption"
                        :format-label="formatPatientLabel" :additional-option="additionalOption"
                        :option-selected="onChangeContact" :key="contactRenderKey" />
                    <span v-if="!contactProfile && isFormSubmitted" class="text-danger">Customer Name is required</span>
                </div>

                <!-- Buyer Name Field -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Buyer Name: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect placeholder="Select Buyer Name" v-model="buyerProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalBuyerOption"
                        :format-label="formatPatientLabel" :additional-option="additionalOption"
                        :option-selected="onChangeContact" :key="contactRenderKey" />
                </div>

                <!-- Sales Person Field -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Sales Person: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect placeholder="Select Sales Person" v-model="salesProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalSalesOption"
                        :format-label="formatPatientLabel" :additional-option="additionalOption"
                        :option-selected="onChangeContact" :key="contactRenderKey" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label class="form-label mt-1">Date</label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="date" placeholder="" v-model="formData.date">
                </div>
                <div class="col-md-3">
                    <label class="form-label">Country of Origin</label>
                    <v-select :options="countryOptions" v-model="formData.country_of_origin" label="name"
                        :reduce="option => option.name" placeholder="Select a Country"></v-select>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Loading Port</label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="text" placeholder="Please add loading port" v-model="formData.loading_port">
                </div>
                <div class="col-md-3">
                    <label class="form-label">Delivery Port</label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="text" placeholder="Please add delivery port"
                        v-model="formData.delivery_port">
                </div>
                <div class="col-md-3">
                    <label class="form-label">Quotation Ref & date</label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="text" placeholder="Please add quotation ref & date"
                        v-model="formData.order_number">
                </div>
                <div class="col-md-3">
                    <label class="form-label">Currency</label>
                    <v-select :options="currencyOptions" v-model="formData.currency" label="name"
                        :reduce="option => option.code" placeholder="Select a Currency"></v-select>
                </div>
            </div>
        </div>
        <div class="px-3">
            <ProductForm @handleProductAdd="handleProductAdd" ref="productForm" />
            <ProductsTable :itemDetails="formData.item_details" @removeBill="removeBill" :formRef="productForm" />
        </div>

        <div class="px-2">
            <div class="row justify-content-end">
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Total Amount</label>
                                </div>
                                <div class="col-sm-8">
                                    <input v-model="total" readonly type="text" class="form-control text-right">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Terms & Condition</label>
                <vField as="textarea" name="description" type="number" class="form-control"
                    v-model="formData.description" />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4">
            <div class="pb-5 px-2 mt-4">
                <div class="d-flex flex-wrap gap-1 gy-2">
                    <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true, true)"
                        class="btn btn-primary">Save & Print</button>
                    <button :disabled="productLoader" @click="navigateToListPage"
                        class="btn btn-outline-secondary">Cancel</button>
                </div>
            </div>
        </div>
        <GlobalLoader />
        <ContactDropdownFilter v-if="store.state.isModalOpenThree" @onSearchContact="onSearchContact" type="supplier"
            :companyRoles="companyRoles" />
    </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleInventory from '@/services/modules/inventory'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'

import { generateTxnNumber } from "@/services/utils/voucherNumberGenerator";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
import { useStore } from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";
import ProductForm from "./ProductForm.vue"
import ProductsTable from "./ProductsTable.vue"
import useDate from '@/services/utils/day'
import handleBarcode from "@/services/modules/barcode";
import pdfPrinter from '@/services/utils/piPdfPrint';
import handleCompany from "@/services/modules/company";

const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const { fetchAccountHead, ...rest } = handlePurchase()
const { fetchCountryOfOrigins } = handleInventory()
const { fetchBusinessList } = handleCBusinesses()
const { fetchBusinessLocationsList } = handleBusinessesLocations()
const { fetchContactProfiles } = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchBarcode } = handleBarcode();
const { formatDate, currentDate } = useDate()
const { exportToPDF } = pdfPrinter();
const { fetchCompanyInfo } = handleCompany();
const isFormSubmitted = ref(false);
const companyId = computed(() => {
    return route.params.companyId
})
const additionalContactOption = ref({
    with_mobile: 1,
    with_donors: 1,
    dealer_id: '',
    roles: 'customer,lead'
});
const additionalBuyerOption = ref({
    with_mobile: 1,
    with_donors: 1,
    dealer_id: '',
    roles: 'buyer'
});
const additionalSalesOption = ref({
    with_mobile: 1,
    with_donors: 1,
    dealer_id: '',
    roles: 'sales_representative'
});
const loader = ref(false)
const productLoader = ref(false)
const saveButtonLoader = ref(false)
const saveNewButtonLoader = ref(false)
const accountHeads = ref([])
const business = ref([])
const locations = ref([])
const salesAccountHeads = ref([])
const accountPayable = ref([])
const prefix = ref('')
const serial = ref('')
const companyInfo = ref({});
const discountAmount = ref(null);
const contactProfile = ref(null);
const buyerProfile = ref(null);
const salesProfile = ref(null);
const productForm = ref(null);
const companyRoles = ref([]);
const grandTotal = ref(0);
const formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    buyer_id: null,
    sales_person_id: null,
    account_head_id: null,
    business_id: null,
    location_id: null,
    currency: null,
    bill_number: '',
    status: 'active',
    date: '',
    has_item_detail: true,
    description: '',
    account_details: [],
    item_details: [],
    discount_amount: 0,
    loading_port: '',
    delivery_port: '',
    country_of_origin: null,
    sale_type: 'pi_order'
})

const countries = ref([])

onMounted(async () => {
    loader.value = true
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=pi_order`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    formData.value.date = currentDate();
    const companyQuery = `?company_id=${companyId.value}`

    const getCountryOfOrigins = fetchCountryOfOrigins(companyQuery)

    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const payableRes = rest.fetchAccountPayable(companyQuery)
    const salesAccountHeadsRes = rest.getAccountHeadBySlag("discount_receipt", companyQuery)
    Promise.all([
        accountHeadRes.then(res => {
            if (res.data) accountHeads.value = res.data
        }),
        businessRes.then(res => {
            if (res.data) business.value = res.data
        }),
        businessLocationRes.then(res => {
            if (res.data) locations.value = res.data
        }),
        fetchCompanyDefaultRoles(companyQuery).then(res => {
            if (res.data) companyRoles.value = res.data
        }),
        payableRes.then(res => {
            if (res.data) {
                accountPayable.value = res.data
            }
            if (res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        salesAccountHeadsRes.then(res => {
            if (res.data) salesAccountHeads.value = res.data
            formData.value.discount_head_id = res.data[0] ? res.data[0].id : null
        }),
        fetchCompanyInfo(companyId.value).then(res => {
            if (res) {
                companyInfo.value = res.data
            }
        }),
        getCountryOfOrigins.then(res => {
            countries.value = res.data
        })
    ])
        .then(() => { loader.value = false })
        .catch(() => { loader.value = false })
})

const isItem = computed(() => {
    return formData.value.has_item_detail
})

const subTotal = computed(() => {
    let subTotal = 0
    if (formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseFloat((item.quantity * item.rate) - (item.discount_amount ?? 0))
        })
    }
    if (!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if (item.amount) { subTotal += parseFloat(item.amount) }
        })
    }

    return subTotal.toFixed(4)
})

const totalVAT = computed(() => {
    let vat = 0
    if (formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            if (item.vat_amount) {
                vat += parseFloat(item.vat_amount)
            }
        })
    }
    if (!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if (item.vat_amount) {
                vat += parseFloat(item.vat_amount)
            }
        })
    }

    return vat.toFixed(4)
})

const total = computed(() => {
    return parseFloat(subTotal.value) + parseFloat(totalVAT.value)
})

const countryOptions = computed(() => {
    return Object.values(countries.value).map(country => {
        return {
            code: country.name,
            name: country.name,
        };
    });
});

const currencyOptions = computed(() =>
    Object.values(countries.value).map(currency => ({
        code: currency.currency,
        name: currency.currency,
    }))
);

const updateGrandTotal = () => {
    grandTotal.value = total.value - parseFloat(discountAmount.value);
}

const updateDiscount = () => {
    discountAmount.value = (total.value - grandTotal.value).toFixed(4);
}

watch(discountAmount, () => {
    formData.value.adjustment_amount = parseFloat(discountAmount.value)
})

watch(total, () => {
    if (discountAmount.value && discountAmount.value !== "") {
        grandTotal.value = total.value - parseFloat(discountAmount.value);
        return;
    }
    grandTotal.value = total.value;
})

watch(contactProfile, (newVal) => {
    if (!newVal) {
        formData.value.contact_profile_id = null;
        return;
    }

    formData.value.contact_profile_id = newVal.id;
})

watch(buyerProfile, (newVal) => {
    if (!newVal) {
        formData.value.buyer_id = null;
        return;
    }

    formData.value.buyer_id = newVal.id;
})

watch(salesProfile, (newVal) => {
    if (!newVal) {
        formData.value.sales_person_id = null;
        return;
    }

    formData.value.sales_person_id = newVal.id;
})

function navigateToListPage() {
    router.push({ name: 'pi-order-list', params: route.params, query: route.query })
}

function onOpenContactSearchModal() {
    store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
    contactProfile.value = contact
}

function removeBill(index) {
    formData.value.item_details.splice(index, 1)
}

const goToPrint = (id) => {
    const user = store.state.user.user
    const companyQuery = `?company_id=${route.params.companyId}`;
    let barcodeQuery = "";
    let qrcode = null
    let barcode = null
    let singleSalesOrder = null

    rest.fetchSalesOrderById(id, companyQuery).then((res) => {
        if (res) {
            singleSalesOrder = res.data;
            let qrcodeData = JSON.stringify({
                ref_no: singleSalesOrder.bill_number,
                ref_date: singleSalesOrder.date,
                created_by: user.name,
                created_date_time: formatDate(new Date())
            })
            barcodeQuery = `?code=${singleSalesOrder.bill_number}&qrcode=${qrcodeData}`;
        }
    }).then(() => {
        fetchBarcode(barcodeQuery).then((res) => {
            barcode = res.barcode;
            qrcode = res.qrcode
        }).then(() => {
            exportToPDF(companyInfo.value, singleSalesOrder, barcode, qrcode, user.name, 1)
        })
    }).catch((err) => {
        console.log(err);
    })
}


async function handleSubmit(redirect = false, print = false) {
    isFormSubmitted.value = true;
    if (!contactProfile.value) {
        return;
    }
    saveNewButtonLoader.value = true

    if (!formData.value.date) formData.value.date = currentDate();

    if (!formData.value.adjustment_amount) {
        formData.value.discount_head_id = null;
    }

    formData.value.bill_number = prefix.value + '-' + serial.value
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    copyFormData.updateSalePrice = 1;
    if (isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    } else {
        delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }

    try {
      let res = await rest.createSalesOrder(copyFormData)
      saveButtonLoader.value = false
      saveNewButtonLoader.value = false

      if (!res.status) {
        return showError(res.message)
      }

      showSuccess(res.message)
      await resetForm()
      if (print && res.data.id) await goToPrint(res.data.id)
      if (redirect) navigateToListPage()

    } catch (err) {
      saveButtonLoader.value = false
      saveNewButtonLoader.value = false
      productLoader.value = false

      if (!err.response) {
        this.showError('Something is wrong. Check your connectivity!!')
        return;
      }

      if (err.response && err.response.status !== 406) {
        showError(err.response?.data.message)
      }
    }
  }

// have to refactor
async function resetForm() {
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=bill_journal`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    formData.value.item_details = []
    formData.value.description = '';
    contactProfile.value = null;
    discountAmount.value = null;
}

const handleProductAdd = (item, index) => {
    if (index !== null && Array.isArray(formData.value.item_details) && !!formData.value.item_details[index]) {
        formData.value.item_details[index] = item;
        return;
    }

    formData.value.item_details.push(item);
}

</script>
